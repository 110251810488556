<template>
  <v-footer dark padless class="w-full">
    <v-card
      flat
      class="indigo lighten-1 white--text text-center"
      style="width: 100%"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon.name"
          class="mx-4 white--text"
          icon
        >
          <a :href="icon.link">
            <v-icon size="24px">
              {{ icon.name }}
            </v-icon></a
          >
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Hengli Moda S.R.L</strong>
        <br />
        Trentino Alto Adige 4 , Prato(PO)
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script setup>
const icons = [
  { name: "mdi-mail", link: "mailto:henglimoda@gmail.com" },
  {
    name: "mdi-whatsapp",
    link: "//api.whatsapp.com/send?phone=393492416839&text=Ti_contatto_dal_sito",
  },
  { name: "mdi-wechat", link: "weixin://dl/chat?lollopenzo" },
];
</script>
