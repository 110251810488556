<template>
  <section aria-labelledby="cause-heading">
    <div class="relative bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-20">
      <div class="absolute inset-0 overflow-hidden">
        <img
          src="https://tailwindui.com/img/ecommerce-images/home-page-03-feature-section-full-width.jpg"
          alt=""
          class="w-full h-full object-center object-cover"
        />
      </div>
      <div
        aria-hidden="true"
        class="absolute inset-0 bg-gray-900 bg-opacity-50"
      />
      <div
        class="relative max-w-3xl mx-auto flex flex-col items-center text-center"
      >
        <h2
          id="cause-heading"
          class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl"
        >
          Chi siamo ?
        </h2>
        <p class="mt-3 text-xl text-white">
          Siamo un'azienda di pronto moda B2B leader nel settore
          dell'abbigliamento Made in Italy. I nostri capi sono interamente
          prodotti in Italia, attingendo dalla bellezza e dallo stile italiano
          utilizzando fibre naturali e coloranti certificati. Proponiamo
          continuamente novità e capi di alta qualità seguendo le tendenze del
          momento, riscontrando sempre un'ottimo interesse del cliente. Da anni
          serviamo i migliori negozi italiani e di tutto il mondo,
          personalizzando i capi in base alle necessità dei nostri clienti,
          dalla ricerca dei migliori tessuti ad un'accurata confezione del capo,
          creando una collaborazione a 360°.
        </p>
      </div>
    </div>
  </section>
</template>
