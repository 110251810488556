<template>
  <section aria-labelledby="favorites-heading">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-baseline sm:justify-between">
        <h2
          id="favorites-heading"
          class="text-2xl font-extrabold tracking-tight text-gray-900"
        >
          I nostri favoriti
        </h2>
        <router-link
          to="/product"
          class="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
          >Guardali tutti<span aria-hidden="true"> &rarr;</span>
        </router-link>
      </div>

      <div
        class="mt-6 grid grid-cols-1 gap-y-10 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 lg:gap-x-8"
      >
        <div
          v-for="favorite in favorites"
          :key="favorite.id"
          class="group relative"
        >
          <div
            class="w-full h-96 rounded-lg overflow-hidden group-hover:opacity-75 sm:h-auto sm:aspect-w-2 sm:aspect-h-3"
          >
            <img
              :src="favorite.imageSrc"
              :alt="favorite.imageAlt"
              class="w-full h-full object-center object-cover"
            />
          </div>
        </div>
      </div>

      <div class="mt-6 sm:hidden">
        <router-link
          to="/product"
          class="block text-sm font-semibold text-indigo-600 hover:text-indigo-500"
          >Guarda tutti i favoriti<span aria-hidden="true">
            &rarr;</span
          ></router-link
        >
      </div>
    </div>
  </section>
</template>

<script setup>
const favorites = [
  {
    id: 1,
    name: "Black Basic Tee",
    price: "",
    href: "#",
    imageSrc: "https://i.postimg.cc/445NLV7n/DSC-7831.jpg",
    imageAlt: "Model wearing women's black cotton crewneck tee.",
  },
  {
    id: 2,
    name: "Off-White Basic Tee",
    price: "",
    href: "#",
    imageSrc: "https://i.postimg.cc/44BsbGq9/DSC-7873.jpg",
    imageAlt: "Model wearing women's off-white cotton crewneck tee.",
  },
  {
    id: 3,
    name: "Mountains Artwork Tee",
    price: "",
    href: "#",
    imageSrc: "https://i.postimg.cc/HL5hS0LN/DSC-7270.jpg",
    imageAlt:
      "Model wearing women's burgundy red crewneck artwork tee with small white triangle overlapping larger black triangle.",
  },
];
</script>
