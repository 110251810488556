<template>
  <v-app>
    <nav-bar />
    <v-main> <router-view /></v-main>
    <footer-bar />
  </v-app>
</template>

<script>
import navBar from "@/components/NavBar.vue";
import footerBar from "@/components/FooterBar.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      //
    };
  },
  components: { navBar, footerBar },
});
</script>
<style>
a {
  text-decoration: none;
}
.router-link-active {
  color: goldenrod;
}
</style>
