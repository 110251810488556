<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable prettier/prettier -->
<template>
  <!-- <v-app-bar app class="pa-8">
      <div class="mx-auto">
        <v-app-bar-title
          ><img
            class="h-20 w-40 ml-6 mb-3"
            src="https://i.postimg.cc/FzMcjMw6/Hengli-Logo-Oro.png"
        /></v-app-bar-title>
        <div>
          <router-link
            class="pr-6 font-extrabold"
            v-for="headerItem in headerItems"
            :key="headerItem.id"
            :to="headerItem.route"
            >{{ headerItem.id }}</router-link
          >
        </div>
      </div>
    </v-app-bar> -->
  <div id="app">
    
      <v-app-bar  app >
        
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-flex  d-sm-none "
        ></v-app-bar-nav-icon>
        <div class="mx-auto pt-10">
        <v-toolbar-title
          ><img
            class="h-20 w-40 ml-6 mb-3"
            src="https://i.postimg.cc/FzMcjMw6/Hengli-Logo-Oro.png"
        /></v-toolbar-title>
        <v-spacer></v-spacer>
</div>

        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title class="d-none d-sm-flex mx-auto mt-10">
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <router-link
              class="pr-6 font-extrabold"
              v-for="headerItem in headerItems"
              :key="headerItem.id"
              :to="headerItem.route"
              >{{ headerItem.id }}</router-link
            >
          </v-tabs>
        </template>
      </v-app-bar>
      <!-- Add a navigation bar -->
      <v-navigation-drawer v-model="drawer" sticky temporary class="pt-8">
        <v-list nav dense>
          <v-list-item-group>
            <v-list-item fixed v-for="headerItem in headerItems" :key="headerItem.id">
              <router-link class="pr-6 font-extrabold " :to="headerItem.route">{{
                headerItem.id
              }}</router-link>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <!-- Navigation bar ends -->
      <v-content class="ma-5">
        <v-tabs-items v-model="tab" class="d-flex flex-column align-center">
          <v-tab-item v-for="item in items" :key="item">
            You are on {{ item }}
          </v-tab-item>
        </v-tabs-items>
      </v-content>
  </div>
</template>
<script setup>
import { ref } from "vue";
const headerItems = [
  { id: "Home", route: "/" },
  { id: "Prodotti", route: "/product" },
  { id: "Contatti", route: "/contact" },
];
const drawer = ref(false);
const tab = ref(null);
</script>
<style>
.bm-menu {
  height: 500px !important; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: rgb(63, 63, 65); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
}
</style>
